<template>
  <FormWrapper
    :formTitle="''"
    :formClass="'yurlitso-form'"
    :v="$v.form"
    :formButton="curformButton"
    :successMessage="curSuccessMessage"
    :formCallback="doYurlitsoEdit"
    @form-success="goFurther"
  >
    <div class="three-fields">
      <TextField
        v-model="form.name"
        :v="$v.form.name"
        :fieldLabel="translations('YurlitsoName')"
      />
      <TextField
        v-model="form.inn"
        :v="$v.form.inn"
        :fieldLabel="translations('Inn')"
      />
      <TextField
        v-model="form.ogrn"
        :v="$v.form.ogrn"
        :fieldLabel="translations('Ogrn')"
      />
    </div>
    <div>
      <TextField
        v-model="form.yur_address"
        :v="$v.form.yur_address"
        :fieldLabel="translations('YurAddress')"
      />
    </div>
    <div class="three-fields">
      <TextField
        v-model="form.rasch_sch"
        :v="$v.form.rasch_sch"
        :fieldLabel="translations('RaschSchet')"
      />
      <TextField
        v-model="form.kpp"
        :v="$v.form.kpp"
        :fieldLabel="translations('Kpp')"
      />
      <TextField
        v-model="form.bik"
        :v="$v.form.bik"
        :fieldLabel="translations('Bik')"
      />
    </div>
    <div>
      <TextField
        v-model="form.bank"
        :v="$v.form.bank"
        :fieldLabel="translations('BankReceiverName')"
      />
    </div>
  </FormWrapper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormWrapper from "@/components/app/forms/FormWrapper";
import TextField from "@/components/app/forms/FieldComponents/TextField";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "UserProfileForm",
  data: () => {
    return {
      form: {
        name: "",
        inn: "",
        ogrn: "",
        yur_address: "",
        rasch_sch: "",
        kpp: "",
        bik: "",
        bank: "",
      },
    };
  },
  props: {
    yurlitsoId: Number,
    curformButton: String,
    curSuccessMessage: String,
  },
  components: { FormWrapper, TextField },
  computed: {
    ...mapGetters(["translations", "listYurlitso"]),
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200),
      },
      inn: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(12),
      },
      ogrn: {
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      yur_address: { maxLength: maxLength(255) },
      rasch_sch: { maxLength: maxLength(100) },
      kpp: { maxLength: maxLength(50) },
      bik: { maxLength: maxLength(50) },
      bank: { maxLength: maxLength(100) },
    },
  },
  watch: {
    "form.inn"(v) {
      //      this.$nextTick(() => {
      this.form.inn = String(v).replace(/\D/g, "").trim().substring(0, 12);
      //      });
    },
  },
  methods: {
    ...mapActions(["userYurlitsoEdit"]),
    async doYurlitsoEdit(recaptchaToken) {
      console.log("начало отправки добавления/редактирования юрлица");
      await this.userYurlitsoEdit({
        id: this.yurlitsoId,
        name: this.form.name,
        inn: this.form.inn,
        ogrn: this.form.ogrn,
        yur_address: this.form.yur_address,
        rasch_sch: this.form.rasch_sch,
        kpp: this.form.kpp,
        bik: this.form.bik,
        bank: this.form.bank,
        token: recaptchaToken,
      });
    },
    goFurther() {
      // дальнейшие действия после успешной отправки формы
      this.$emit("update-list");
      setTimeout(() => {
        this.$emit("close-popup");
      }, 500);
    },
  },
  mounted() {
    if (this.yurlitsoId) {
      const yurlitso = this.listYurlitso(this.yurlitsoId);
      this.form.name = yurlitso.name;
      this.form.inn = yurlitso.inn;
      this.form.ogrn = yurlitso.ogrn;
      this.form.yur_address = yurlitso.yur_address;
      this.form.rasch_sch = yurlitso.rasch_sch;
      this.form.kpp = yurlitso.kpp;
      this.form.bik = yurlitso.bik;
      this.form.bank = yurlitso.bank;
    } else {
      this.form.name = "";
      this.form.inn = "";
      this.form.ogrn = "";
      this.form.yur_address = "";
      this.form.rasch_sch = "";
      this.form.kpp = "";
      this.form.bik = "";
      this.form.bank = "";
    }
  },
};
</script>
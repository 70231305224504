<template>
  <div class="documents-container yourlitsa-container">
    <PaginationTable
      :itemsInPage="itemsPerPage"
      :getTotalCount="itemsCount"
      :getPageContent="fetchPage"
      :addItemsFull="false"
    >
      <template v-slot:header>
        <div class="header-filter">
          <h2>{{ translations("MyYurLitsa") }}</h2>
          <button
            type="button"
            class="btn btn-blue btn-big"
            @click="addYurlitso"
          >
            <span>{{ translations("AddYurlitso") }}</span>
          </button>
        </div>
      </template>
      <template v-slot:document="{ curItem, refresh }">
        <div
          class="yourlitso-content"
          :class="{ default: curItem.default_use }"
        >
          <div class="header-buttons">
            <div class="choose-default">
              <div class="radio">
                <div class="radio-inner">
                  <input
                    :id="'default-' + curItem.id"
                    type="radio"
                    name="default"
                    @click="setDefault(curItem.id)"
                    :checked="curItem.default_use"
                  />
                  <i></i>
                </div>
                <label :for="'default-' + curItem.id">{{
                  translations("UseYourlitsoWhileOrdering")
                }}</label>
              </div>
              <div class="edit-area">
                <button type="button" @click="editYurlitso(curItem.id)">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.4245 2.79226C10.0883 1.06925 7.62318 1.06925 7.28704 2.79226C7.08249 3.84073 5.92677 4.3973 4.9795 3.90351C3.42282 3.09203 1.88582 5.01938 3.02334 6.35646C3.71554 7.17009 3.4301 8.42069 2.45343 8.85341C0.848415 9.56453 1.39697 11.9679 3.15157 11.9122C4.21928 11.8783 5.01906 12.8812 4.74844 13.9146C4.3037 15.6128 6.52474 16.6824 7.57518 15.2759C8.21438 14.42 9.49714 14.42 10.1363 15.2759C11.1868 16.6824 13.4078 15.6128 12.9631 13.9146C12.6925 12.8812 13.4922 11.8783 14.5599 11.9122C16.3146 11.9679 16.8631 9.56453 15.2581 8.85341C14.2814 8.42069 13.996 7.17009 14.6882 6.35646C15.8257 5.01938 14.2887 3.09203 12.732 3.90351C11.7848 4.3973 10.629 3.84073 10.4245 2.79226ZM8.85576 11.2512C10.0984 11.2512 11.1058 10.2438 11.1058 9.00117C11.1058 7.75853 10.0984 6.75117 8.85576 6.75117C7.61312 6.75117 6.60576 7.75853 6.60576 9.00117C6.60576 10.2438 7.61312 11.2512 8.85576 11.2512Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  @click="deleteYurlitso(curItem.id, refresh)"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5714 2.875H11.3571L11.1054 2.36368C11.052 2.25436 10.9699 2.16241 10.8681 2.09816C10.7664 2.03391 10.6491 1.99992 10.5295 2H7.46786C7.34849 1.99954 7.23142 2.0334 7.13004 2.09772C7.02866 2.16204 6.94708 2.25421 6.89464 2.36368L6.64286 2.875H3.42857C3.31491 2.875 3.2059 2.9211 3.12553 3.00315C3.04515 3.08519 3 3.19647 3 3.3125V4.1875C3 4.30354 3.04515 4.41482 3.12553 4.49686C3.2059 4.57891 3.31491 4.625 3.42857 4.625H14.5714C14.6851 4.625 14.7941 4.57891 14.8745 4.49686C14.9548 4.41482 15 4.30354 15 4.1875V3.3125C15 3.19647 14.9548 3.08519 14.8745 3.00315C14.7941 2.9211 14.6851 2.875 14.5714 2.875ZM4.425 14.7695C4.44544 15.1027 4.58951 15.4155 4.82787 15.6441C5.06624 15.8727 5.38098 16 5.70804 16H12.292C12.619 16 12.9338 15.8727 13.1721 15.6441C13.4105 15.4155 13.5546 15.1027 13.575 14.7695L14.1429 5.5H3.85714L4.425 14.7695Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="text">
            <h5>{{ curItem.inn }}</h5>
            <h3>{{ curItem.name }}</h3>
            <p v-if="curItem.yur_address">
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 16.1749L12.7125 12.4624C13.4467 11.7282 13.9466 10.7927 14.1492 9.77435C14.3517 8.75596 14.2477 7.70039 13.8503 6.74111C13.4529 5.78183 12.78 4.96192 11.9167 4.38507C11.0533 3.80821 10.0383 3.50032 9 3.50032C7.96167 3.50032 6.94666 3.80821 6.08332 4.38507C5.21997 4.96192 4.54706 5.78183 4.14969 6.74111C3.75231 7.70039 3.64831 8.75596 3.85084 9.77435C4.05337 10.7927 4.55333 11.7282 5.2875 12.4624L9 16.1749ZM9 18.2959L4.227 13.5229C3.28301 12.5789 2.64014 11.3762 2.3797 10.0668C2.11925 8.75746 2.25293 7.40026 2.76382 6.16687C3.27472 4.93347 4.13988 3.87927 5.24991 3.13757C6.35994 2.39588 7.66498 2 9 2C10.335 2 11.6401 2.39588 12.7501 3.13757C13.8601 3.87927 14.7253 4.93347 15.2362 6.16687C15.7471 7.40026 15.8808 8.75746 15.6203 10.0668C15.3599 11.3762 14.717 12.5789 13.773 13.5229L9 18.2959ZM9 10.2499C9.39783 10.2499 9.77936 10.0919 10.0607 9.8106C10.342 9.5293 10.5 9.14777 10.5 8.74994C10.5 8.35212 10.342 7.97059 10.0607 7.68928C9.77936 7.40798 9.39783 7.24994 9 7.24994C8.60218 7.24994 8.22065 7.40798 7.93934 7.68928C7.65804 7.97059 7.5 8.35212 7.5 8.74994C7.5 9.14777 7.65804 9.5293 7.93934 9.8106C8.22065 10.0919 8.60218 10.2499 9 10.2499ZM9 11.7499C8.20435 11.7499 7.44129 11.4339 6.87868 10.8713C6.31607 10.3087 6 9.54559 6 8.74994C6 7.95429 6.31607 7.19123 6.87868 6.62862C7.44129 6.06601 8.20435 5.74994 9 5.74994C9.79565 5.74994 10.5587 6.06601 11.1213 6.62862C11.6839 7.19123 12 7.95429 12 8.74994C12 9.54559 11.6839 10.3087 11.1213 10.8713C10.5587 11.4339 9.79565 11.7499 9 11.7499Z"
                  fill="#7D7E8B"
                /></svg
              >{{ curItem.yur_address }}
            </p>
            <p v-if="curItem.agreement_number">
              <a
                target="_blank"
                :href="'//' + imagesPath + curItem.agreement"
                >{{
                  translations("PersonalAgreementNo", [
                    curItem.agreement_number,
                    curItem.agreement_date_txt,
                  ])
                }}</a
              >
            </p>
          </div>
        </div>
      </template>
      <template v-slot:empty>
        <div class="empty-list">
          <svg
            width="124"
            height="109"
            viewBox="0 0 124 109"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.7383 18.7344V24.9453C74.7383 26.6842 73.3721 28.0508 71.6328 28.0508H68.5273V18.7344C68.5273 16.9951 67.1611 15.6289 65.4219 15.6289H40.5781C38.8389 15.6289 37.4727 16.9951 37.4727 18.7344V28.0508H34.3672C32.6279 28.0508 31.2617 26.6842 31.2617 24.9453V18.7344C31.2617 13.5791 35.4228 9.41797 40.5781 9.41797H65.4219C70.5772 9.41797 74.7383 13.5791 74.7383 18.7344Z"
              fill="#5A5A5A"
            />
            <path
              d="M65.4219 15.6289H53V9.41797H65.4219C70.5772 9.41797 74.7383 13.5791 74.7383 18.7344V24.9453C74.7383 26.6842 73.3721 28.0508 71.6328 28.0508H68.5273V18.7344C68.5273 16.9951 67.1611 15.6289 65.4219 15.6289Z"
              fill="#5A5A5A"
            />
            <path
              d="M106 24.9452V93.2656C106 98.4204 101.839 102.582 96.6836 102.582H9.31641C4.16112 102.582 0 98.4204 0 93.2656V24.9452C0 24.0757 0.372656 23.3304 0.931848 22.7712L16.7695 58.4222H89.2305L105.068 22.771C105.627 23.3304 106 24.0757 106 24.9452Z"
              fill="#5A5A5A"
            />
            <path
              d="M106 24.9452V93.2656C106 98.4204 101.839 102.582 96.6836 102.582H53V58.4222H89.2305L105.068 22.771C105.627 23.3304 106 24.0757 106 24.9452Z"
              fill="#5A5A5A"
            />
            <path
              d="M105.068 22.7713L95.4412 58.4223C94.3232 62.5213 90.659 65.3164 86.4353 65.3164H19.5645C15.3406 65.3164 11.6764 62.5215 10.5584 58.4223L0.931641 22.7713C1.49042 22.2125 2.23573 21.8398 3.10526 21.8398H102.894C103.764 21.8398 104.509 22.2125 105.068 22.7713Z"
              fill="#6E6E6E"
            />
            <path
              d="M105.068 22.7713L95.4414 58.4223C94.3234 62.5213 90.6592 65.3164 86.4356 65.3164H53V21.8398H102.895C103.764 21.8398 104.509 22.2125 105.068 22.7713Z"
              fill="#6E6E6E"
            />
            <path
              d="M65.4219 56H40.5781C38.8389 56 37.4727 57.3662 37.4727 59.1055V71.5273C37.4727 73.2662 38.8389 74.6328 40.5781 74.6328H65.4219C67.1611 74.6328 68.5273 73.2662 68.5273 71.5273V59.1055C68.5273 57.3662 67.1611 56 65.4219 56Z"
              fill="#FFD400"
            />
            <path
              d="M68.5273 59.1055V71.5273C68.5273 73.2662 67.1611 74.6328 65.4219 74.6328H53V56H65.4219C67.1611 56 68.5273 57.3662 68.5273 59.1055Z"
              fill="#FDBF00"
            />
            <path
              d="M102 44C114.15 44 124 34.1503 124 22C124 9.84974 114.15 0 102 0C89.8497 0 80 9.84974 80 22C80 34.1503 89.8497 44 102 44Z"
              fill="#F15249"
            />
            <path
              d="M92.7695 31.9243L104.637 43.7918C114.631 42.612 122.542 34.7697 123.792 24.776L111.855 12.8391L92.7695 31.9243Z"
              fill="#AD0E0E"
            />
            <path
              d="M112.41 26.8582C113.382 27.8298 113.382 29.4955 112.41 30.4671L110.467 32.4103C109.495 33.3819 107.83 33.3819 106.858 32.4103L91.59 17.1422C90.6184 16.1705 90.6184 14.5049 91.59 13.5333L93.6027 11.5207C94.5743 10.5491 96.2399 10.5491 97.2115 11.5207L112.41 26.8582Z"
              fill="white"
            />
            <path
              d="M104.36 18.7383L98.7383 24.3597L106.858 32.4102C107.83 33.3818 109.495 33.3818 110.467 32.4102L112.48 30.3976C113.451 29.426 113.451 27.7604 112.48 26.7888L104.36 18.7383Z"
              fill="#D6D6D6"
            />
            <path
              d="M106.858 11.59C107.83 10.6184 109.495 10.6184 110.467 11.59L112.48 13.6027C113.451 14.5743 113.451 16.2399 112.48 17.2115L97.1421 32.4102C96.1705 33.3818 94.5049 33.3818 93.5333 32.4102L91.59 30.467C90.6184 29.4954 90.6184 27.8298 91.59 26.8582L106.858 11.59Z"
              fill="white"
            />
          </svg>
          <p>{{ translations("UserYurlitsaEmpty") }}</p>
        </div>
      </template>
      <template v-slot:update>
        <Popuper
          v-if="showModal"
          :width="'800px'"
          @close="showModal = false"
          @update-list="$emit('update-list')"
        >
          <template v-slot:header>
            {{
              curYurLitsoId
                ? translations("EditUserYurlitso")
                : translations("AddUserYurlitso")
            }}
          </template>
          <template v-slot:body>
            <YurlitsoForm
              :curformButton="
                curYurLitsoId
                  ? translations('SaveChanges')
                  : translations('AddYurlitso')
              "
              :curSuccessMessage="
                curYurLitsoId
                  ? translations('YourYurlitsoUpdated')
                  : translations('YourYurlitsoAdded')
              "
              :yurlitsoId="curYurLitsoId"
              @close-popup="showModal = false"
              @update-list="$emit('update-list')"
            />
          </template>
          <template v-slot:footer></template>
        </Popuper>
      </template>
    </PaginationTable>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import Popuper from "@/components/app/Popuper";
import PaginationTable from "@/components/PaginationTable";
import YurlitsoForm from "@/components/app/forms/account/YurlitsoForm";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserYurlitsa",
  components: {
    PaginationTable,
    YurlitsoForm,
    Popuper,
  },
  data: () => {
    return {
      imagesPath: VUE_APP_BACK_URL,
      itemsPerPage: 5,
      curYurLitsoId: null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "listYurlitsaLoaded",
      "listYurlitsaCount",
      "listYurlitsa",
      "listYurlitsaPage",
      "listYurlitso",
    ]),
  },
  methods: {
    ...mapActions(["userYurlitsa", "userYurlitsoUse", "userYurlitsoDelete"]),
    async setDefault(id) {
      console.log("меняем по умолчанию + сами юрлица: ", id);
      await this.userYurlitsoUse(id);
    },
    async itemsCount() {
      console.log("получаем кол-во юрлиц 22 + сами юрлица");
      if (!this.listYurlitsaLoaded) {
        await this.userYurlitsa({
          page: +this.$route.query.page || 1,
          pagesize: this.itemsPerPage,
        });
      }
      return this.listYurlitsaCount;
    },
    async fetchPage(page) {
      console.log("page: ", +page);
      console.log("YurlitsaPage: ", this.listYurlitsaPage);
      if (+this.listYurlitsaPage !== +page) {
        console.log("получаем сами юрлица 22");
        await this.userYurlitsa({ page, pagesize: this.itemsPerPage });
      }
      return this.listYurlitsa;
    },
    addYurlitso() {
      this.curYurLitsoId = null;
      this.showModal = true;
    },
    editYurlitso(id) {
      this.curYurLitsoId = id;
      this.showModal = true;
    },
    async deleteYurlitso(id, refresh) {
      await this.userYurlitsoDelete(id);
      refresh();
    },
  },
  async mounted() {
    //this.curItemsCount = await this.itemsCount();
  },
};
</script>